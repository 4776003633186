import { Component, OnDestroy } from '@angular/core';
import { FullDayAbsenceRequestService } from './fullDay-absence-request.service';

@Component({
  selector: 'app-full-day-absence-request',
  templateUrl: './fullDay-absence-request.component.html'
})
export class FullDayAbsenceRequestComponent implements OnDestroy {

  constructor(public fullDayAbsenceRequestService: FullDayAbsenceRequestService) { }

  ngOnDestroy() {
    this.fullDayAbsenceRequestService.step = 1;
    this.fullDayAbsenceRequestService.absence =  {
      groupingId: null,
      startDate: null,
      endDate: null,
      counterCode: null,
      counterLabel: null,
      comment: null
    };
  }

}
