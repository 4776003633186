import {Component, inject, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Task } from 'src/app/shared/models/home/task';
import { storageKeys } from 'src/app/shared/constants/constants';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-due-date-modal',
  templateUrl: './dueDateModal.component.html',
  styleUrls: ['./dueDateModal.component.scss']
})
export class DueDateModalComponent {

  public activeModal: NgbActiveModal = inject(NgbActiveModal);
  public userService: UserService = inject(UserService);

  @Input() task: Task;

  remindMeLater() {
    this.activeModal.close();
    const expiryDate = new Date().getTime() + 60 * 60 * 24 * 1000;
    localStorage.setItem(`${storageKeys.DUE_DATE_MODAL_EXPIRY}_${this.task.title}_${this.userService.adminNumber}`, expiryDate.toString());
  }

}
