import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { SettingsActivation } from 'src/app/shared/enums/setting/typology-settings/settingsActivation.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-absence-home',
  templateUrl: './absence-home.component.html',
  styleUrls: ['./absence-home.component.scss']
})
export class AbsenceHomeComponent implements OnInit {

  partialAbsenceEnabled = false;
  fullDayAbsenceEnabled = false;
  private destroyRef = inject(DestroyRef);

  constructor(private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSetting => {
      this.partialAbsenceEnabled = typoSetting.absenceRequestSettings.partialAbsence.status === SettingsActivation.ACTIVE;
      this.fullDayAbsenceEnabled = typoSetting.absenceRequestSettings.fullDayAbsence.status === SettingsActivation.ACTIVE;
    });
  }

  navigateToPartial() {
    this.router.navigate(['/absence-request/partial']);
  }

  navigateToFull() {
    this.router.navigate(['/absence-request/full']);
  }


}
