import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { WebsocketConnectionService } from 'src/app/shared/services/websocket-connection.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {AuthenticationService} from '../../auth.service';

@Component({
  selector: 'app-badge-connection',
  templateUrl: './badge-connection.component.html',
  styleUrls: ['./badge-connection.component.scss']
})
export class BadgeConnectionComponent implements OnInit {
  private websocketService = inject(WebsocketConnectionService);
  private authenticationService = inject(AuthenticationService);
  private destroyRef = inject(DestroyRef);

  badgeCode = '';
  isManualBadge: boolean;

  async ngOnInit() {
    await this.websocketService.init();
    this.sendReaderIDToWebsocket();
    this.reSendMessageWhenWebSocketClosed();
  }

  get isButtonDisabled() {
    return this.badgeCode.trim() === '';
  }


  sendReaderIDToWebsocket() {
    const defaultDevice = localStorage.getItem('default_device');
    if (defaultDevice) {
      const parsedDevice = JSON.parse(defaultDevice);
      this.isManualBadge = parsedDevice.badgeType === 'MANUAL';
      const readerId = parsedDevice.readerId;
      const message = JSON.stringify({ readerId });
      this.websocketService.sendMessage(message);
    }
  }

  reSendMessageWhenWebSocketClosed() {
    this.websocketService.WebSocketReOpened
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      this.sendReaderIDToWebsocket();
    });
  }

  onSubmit(): void {
    const defaultDevice = localStorage.getItem('default_device');
    if (defaultDevice) {
      const readerId = JSON.parse(defaultDevice).readerId;
      const request = JSON.stringify({ 'badgeCode': this.badgeCode, readerId });
      this.authenticationService.badgeAuthentication(request).subscribe();
    }
  }
}
