import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from '../documents.service';
import { IFile } from '@shared/interfaces/IFile';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  public route = inject(ActivatedRoute);
  public documentsService = inject(DocumentsService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  filePath: string;
  file: IFile;


  ngOnInit() {
    this.route.queryParamMap
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(params => {
      this.file = {
        name: params.get('name'),
        lastModified: params.get('lastModified'),
        creationDate: params.get('creationDate'),
        type: params.get('type'),
        size: Number(params.get('size')),
        path: params.get('path'),
        description: params.get('description')
      };
      this.getDownloadLink();
    });
  }

  getDownloadLink() {
    this.documentsService.getFilePath(this.file.path)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(res => {
      this.filePath = res;
    });
  }

  getBack() {
    this.router.navigate(['/documents']);
  }
}
