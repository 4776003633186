import {Component, inject, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkin-conditions',
  templateUrl: './checkInConditions.component.html',
  styleUrls: ['./checkInConditions.component.scss']
})
export class CheckInConditionsComponent {

  public activeModal = inject(NgbActiveModal);

  @Input() conditions: string;
  conditionValidated = false;

  validate() {
    if (this.conditionValidated) {
      this.activeModal.close();
    }
  }
}
