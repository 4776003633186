import { Router } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { Component, OnInit, HostListener, ElementRef, ViewChild, inject, DestroyRef } from '@angular/core';
import { Message } from 'src/app/shared/models/message/message';
import { MessagesService } from './messages.service';
import { MessageGroupResponse, MessageGroup } from 'src/app/shared/models/message/messageGroup';
import { MessageType } from 'src/app/shared/enums/message/messageType.enum';
import { MessageDateGroup } from 'src/app/shared/enums/message/messageDateGroup.enum';
import { MessageAction } from 'src/app/shared/models/message/message.action';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UserService } from 'src/app/shared/services/user.service';
import { SettingsActivation } from 'src/app/shared/enums/setting/typology-settings/settingsActivation.enum';
import { MessagesCountService } from 'src/app/shared/services/messages-count.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  @ViewChild('messageSearchInput', { static: true }) messageSearchInput: ElementRef;

  keyWords: string;
  messageGroupResponse: MessageGroupResponse = {
    messageGroups: [],
    hasNext: false
  };
  messageType = MessageType;
  messageAction: MessageAction;
  pagination = {
    page: 0,
    hasNext: false
  };
  loading = true;
  swapTypes = [
    this.messageType.ACCEPTANCE_EXCHANGE_SHIFT,
    this.messageType.ACCEPTANCE_REFUSAL_REST,
    this.messageType.PROPOSAL_EXCHANGE_SHIFT,
    this.messageType.PROPOSAL_EXCHANGE_REST,
    this.messageType.VALIDATION_REFUSAL_REST,
    this.messageType.VALIDATION_REFUSAL_SHIFT
  ];
  showMessageActions: Message;
  swapRequestIsActive: boolean;
  private messageService = inject(MessagesService);
  private messagesCountService = inject(MessagesCountService);
  private modalService = inject(NgbModal);
  private router = inject(Router);
  public userService = inject(UserService);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.getMessages();
    fromEvent(this.messageSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2 || res.length === 0)
      , debounceTime(1000)
      , distinctUntilChanged()
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.search(this.keyWords);
      });
    this.userService.typologySetting
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(typoSetting => {
        this.swapRequestIsActive = typoSetting.swapRequestSettings.status !== SettingsActivation.INACTIVE;
      });
    // update unread message count
    this.messagesCountService.getUnreadMessageCount();
  }

  search(keywords: string) {
    this.messageService.search(keywords)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((messageGroupResponse: MessageGroupResponse) => {
        this.messageGroupResponse = messageGroupResponse;
        this.pagination.hasNext = messageGroupResponse.hasNext;
      });
  }

  getMessages(more = false) {
    this.loading = true;
    this.messageService.getMessagesGroup(this.pagination.page)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (messageGroupResponse: MessageGroupResponse) => {
          if (more) {
            const todayMessages =
              this.filterByGroupDate(MessageDateGroup.TODAY, this.messageGroupResponse.messageGroups)
                .concat(this.filterByGroupDate(MessageDateGroup.TODAY, messageGroupResponse.messageGroups));
            const yesterdayMessages =
              this.filterByGroupDate(MessageDateGroup.YESTERDAY, this.messageGroupResponse.messageGroups)
                .concat(this.filterByGroupDate(MessageDateGroup.YESTERDAY, messageGroupResponse.messageGroups));
            const weekMessages =
              this.filterByGroupDate(MessageDateGroup.THIS_WEEK, this.messageGroupResponse.messageGroups)
                .concat(this.filterByGroupDate(MessageDateGroup.THIS_WEEK, messageGroupResponse.messageGroups));
            const otherMessages =
              this.filterByGroupDate(MessageDateGroup.BEFORE, this.messageGroupResponse.messageGroups)
                .concat(this.filterByGroupDate(MessageDateGroup.BEFORE, messageGroupResponse.messageGroups));
            const messageGroups: MessageGroup[] = [];
            messageGroups.push({ group: MessageDateGroup.TODAY, label: 'MESSAGE.GROUPMESSAGE.TODAY', messages: todayMessages});
            messageGroups.push({ group: MessageDateGroup.YESTERDAY, label: 'MESSAGE.GROUPMESSAGE.YESTERDAY', messages: yesterdayMessages });
            messageGroups.push({ group: MessageDateGroup.THIS_WEEK, label: 'MESSAGE.GROUPMESSAGE.THIS_WEEK', messages: weekMessages });
            messageGroups.push({ group: MessageDateGroup.BEFORE, label: 'MESSAGE.GROUPMESSAGE.BEFORE', messages: otherMessages });
            this.messageGroupResponse.messageGroups = messageGroups;
          } else {
            this.messageGroupResponse = messageGroupResponse;
          }
          this.loading = false;
          this.pagination.page++;
          this.pagination.hasNext = messageGroupResponse.hasNext;
        }
      });
  }

  markAsRead(message: Message) {
    const messageDetail = (({ favorite, markedRead, archived, status }) => ({ favorite, markedRead, archived, status }))(message);

    messageDetail.markedRead = !messageDetail.markedRead;

    message.showActions = false;
    this.messageService.markMessageAsRead(message)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      Object.assign(message, messageDetail);
      this.messagesCountService.getUnreadMessageCount();
    });
  }

  addToFavorites(message: Message) {
    const messageDetail = (({ favorite, markedRead, archived, status }) => ({ favorite, markedRead, archived, status }))(message);

    messageDetail.favorite = !messageDetail.favorite;

    message.showActions = false;
    this.messageService.addMessageToFavorites(message)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      Object.assign(message, messageDetail);
    });
  }

  archive(message: Message) {
    const messageDetail = (({ favorite, markedRead, archived, status }) => ({ favorite, markedRead, archived, status }))(message);

    messageDetail.archived = !messageDetail.archived;

    message.showActions = false;
    this.messageService.archiveMessage(message)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      Object.assign(message, messageDetail);

    });
  }


  displayActions(event: any, message: Message = null) {
    event.stopPropagation();
    if (this.showMessageActions) {
      this.showMessageActions.showActions = false;
    }
    if (message) {
      message.showActions = true;
      this.showMessageActions = message;
    }
  }

  filterByGroupDate(group: MessageDateGroup, messageGroup: MessageGroup[]): Message[] {
    return messageGroup.find(message => message.group === group).messages;
  }

  openMessage(message: Message) {
    if (!message.markedRead) {
      this.markAsRead(message);
    }
    if (message.type === this.messageType.CUSTOM_MESSAGE) {
      const modalRef = this.modalService.open(ModalComponent, { centered: true, size: 'xl', scrollable: true });
      modalRef.componentInstance.message = message;
    }
    if (message.type === this.messageType.UPDATED_SHIFT) {
      if (message.concernDate !== null) {
        this.router.navigate(['/planning/details'], { queryParams: { from: moment(message.concernDate).format('YYYY-MM-DD') } });
      }
    }
    if ((this.swapTypes.includes(message.type)) && this.swapRequestIsActive) {
      this.router.navigate(['/swap/history']);
    }
    if (message.type === this.messageType.VALIDATION_REFUSAL_ABSENCE) {
      this.router.navigate(['/absence/history']);
    }
    if (message.type === this.messageType.RELAUNCH_SURVEY) {
      this.router.navigate(['/surveys']);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event): void {
    // Determine if an element has been totally scrolled
    const element = event.target;
    if ( element.scrollHeight - Math.ceil(element.scrollTop) <= element.clientHeight && this.pagination.hasNext && !this.loading) {
      this.getMessages(true);
    }
  }

}
