import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { UserService } from 'src/app/shared/services/user.service';
import { PrepaySettings } from 'src/app/shared/models/settings/prepaySettings/PrepaySettings';
import { SubtotalTypeEnum } from 'src/app/features/prepay/enums/subtotalType.enum';
import { ActivatedRoute } from '@angular/router';
import { PositionStage } from 'src/app/shared/enums/setting/prepay-settings/positionStage.enum';
import { CounterDisplayEnum } from 'src/app/shared/enums/setting/prepay-settings/counter-display.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-prepay-counters',
  templateUrl: './prepay-counters.component.html',
  styleUrls: ['./prepay-counters.component.scss']
})

export class PrepayCountersComponent implements OnInit {

  @Input() countersArray: any;
  @Input() countersArrayHearders: any;
  @Input() counterDisplay: string;
  format = Format;
  headers = [];
  tableRows = [];
  counters = [];
  lines = [];
  lineSubTotal = [];
  balances = [];
  prepayCounters = [];
  listCounters = [];
  data: any;
  lineTotals = [];
  prepaySettings: PrepaySettings;
  subtotalTypeEnum = SubtotalTypeEnum;
  now: Date;
  periodEnd: Date;
  int18Prefix = 'PREPAY.';
  public positionStage = PositionStage;
  public counterDisplayEnum = CounterDisplayEnum;
  private destroyRef = inject(DestroyRef);

  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.countersArray.prepayDataDtos && this.countersArray.prepayDataDtos.length !== 0) {
      this.countersArray.prepayDataDtos.forEach((x) => this.listCounters.push(x.counterMovmentDtos));
      this.data = this.countersArray.prepayDataDtos;
      this.makeHeaders(this.countersArray);
      this.makeLinesOfTable(this.countersArray);
      this.tableRows = this.countersArray.prepayDataDtos;
    }
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSetting => {
      this.prepaySettings = typoSetting.prepaySettings;
    });
    this.now = new Date();
    this.periodEnd = new Date(this.route.snapshot.paramMap.get('end'));
  }

  makeHeaders(data) {
    data.prepayDataDtos.forEach((element) => {
      element.counterMovmentDtos.forEach((item) => {
        if (!this.headers.some(el => el.code === item.code)) {
          this.headers.push({
            code: item.code,
            label: item.label,
            unit: item.unit,
            key: item.code + item.unit,
            codePresentation: item.codePresentation
          });
        }
        item.key = item.code + item.unit;
      });
    });
    this.sortHeaders();
  }

  sortHeaders() {
    const sort = this.countersArrayHearders;
    this.headers.sort((a, b) => {
      return sort.indexOf(a.code) - sort.indexOf(b.code);
    });
  }

  RoundWith2decimal(num: number): number {
    return Math.round(num * 100) / 100;
  }

  makeLinesOfTable(data) {
    const lines: any[] = [];
    const lineSubTotal: any[] = [];
    const balances: any[] = [];
    let line = [];
    let subTotal = [];
    let total = [];
    data.prepayDataDtos.forEach((elem) => {
      line = [];
      subTotal = [];
      total = [];
      this.headers.forEach(x => {
        const foundCounter = elem.counterMovmentDtos.find((counter) => counter.key === x.key);
        if (foundCounter && foundCounter.quantity != null) {
          line.push(this.RoundWith2decimal(foundCounter.quantity));
        } else {
          line.push(null);
        }

        if (foundCounter && foundCounter.subTotal != null) {
          subTotal.push(this.RoundWith2decimal(foundCounter.subTotal.quantity));
        } else {
          subTotal.push(null);
        }

        if (foundCounter && foundCounter.balance != null) {
          total.push({ header: x.code, unit: x.unit, balance: this.RoundWith2decimal(foundCounter.balance) });
        } else {
          total.push(null);
        }
      });
      lines.push(line);
      lineSubTotal.push(subTotal);
      balances.push(total);
    });

    this.lines = [...lines];
    this.lineSubTotal = [...lineSubTotal];
    this.balances = [...balances];

    this.makeTotalsToDisplay();
  }

  makeTotalsToDisplay() {
    const lineTotals = [];
    this.headers.forEach(codeHeader => {
      this.balances.forEach(elem => {
        elem.forEach(item => {
          if (item != null
            && item.header === codeHeader.code
            && !lineTotals.some(el => el.header === codeHeader.code)) {
            lineTotals.push(item);
          }
        });
      });
    });

    this.lineTotals = [lineTotals];
  }
}
