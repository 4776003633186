import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HomeService} from '../home.service';
import {SnackbarService} from 'src/app/shared/components/snackbar/snackbar.service';
import {SelectableItem} from 'src/app/shared/interfaces/selectableItem';
import {UserService} from 'src/app/shared/services/user.service';
import {Shift} from 'src/app/shared/models/home/shift';
import * as moment from 'moment';
import {ITime} from './Time.inteface';
import {CiCoStatusEnum} from 'src/app/shared/enums/home/check-in-out-status.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-checkout-conditions',
    templateUrl: './checkOutConditions.component.html',
    styleUrls: ['./checkOutConditions.component.scss']
})
export class CheckOutConditionsComponent implements OnInit {

    @Input() date: string;
    @Input() shiftPartIndex: number;
    @Input() showConditions = false;
    @Input() conditions: string;
    @Input() shiftData: Shift;
    @Input() openedModalTime: ITime;
    conditionValidated = false;
    delayTypes: Array<SelectableItem> = [];
    selectedOption: string;
    broadcastTime: ITime;
    isTimeValid = true;

    private userService = inject(UserService);
    private snackbarService = inject(SnackbarService);
    private homeService = inject(HomeService);
    private destroyRef = inject(DestroyRef);
    public activeModal = inject(NgbActiveModal);

    constructor() {
      this.userService.typologySetting
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(typo => {
        this.delayTypes = typo.shiftSettings.checkOut.delayTypes;
      });
    }

    ngOnInit() {
      this.getArrivalDate();
    }

    validate() {
        if (this.getConditionValidated && this.isTimeValid) {
            const date = moment(this.shiftData.details.arrival.date)
                .set({ hour: this.broadcastTime.hour, minute: this.broadcastTime.minute })
                .format('YYYY-MM-DD HH:mm');
            this.homeService.validateCheckOut(this.date, this.shiftPartIndex, date, this.selectedOption)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: data => {
                        if (data.checkInOutStatusType !== CiCoStatusEnum.SUCCESS) {
                            this.snackbarService.show(`PLANNING.NOTIFICATIONS.CHECKOUT.${data.checkInOutStatusType}`);
                            return;
                        }
                      this.snackbarService.show('PLANNING.NOTIFICATIONS.CHECKOUT.SUCCESS', 'success', true);
                      this.activeModal.close(data.showCheckInOutType);
                    },
                    error: () => {
                        this.snackbarService.show('PLANNING.NOTIFICATIONS.CHECKOUT.GENERAL_ERROR', 'error');
                    }
                }
                );
        }
    }

    getArrivalDate() {
        const time = moment(this.shiftData.details.arrival.date);
        this.broadcastTime = {
            hour: time.hours(),
            minute: time.minutes(),
            date: this.shiftData.details.arrival.date
        };
    }

    validateTime(hour: number, minute: number) {
        const date = this.shiftData.details.arrival.date;
        this.isTimeValid = this._validateTime(Number(hour), Number(minute));
        if (this.isTimeValid) {
            this.broadcastTime = {hour, minute, date};
        }
    }

    private _validateTime(selectedHour: number, selectedMinute: number): boolean {
        const time = moment(this.shiftData.details.arrival.date);
        const arrivalTime = new Date(this.shiftData.details.arrival.date.substr(0, 10));
        const departureTime = new Date(this.shiftData.details.departure.date.substr(0, 10));
        const modalTime = new Date(this.openedModalTime.date.substr(0, 10));

        if (arrivalTime > departureTime &&  modalTime.getTime() === arrivalTime.getTime()) {
            this.broadcastTime.hour = this.broadcastTime.hour + 24;
            this.openedModalTime.hour = this.openedModalTime.hour + 24;
        }
        if (Number(selectedHour) > 35) {
            this.broadcastTime.hour = 35;
            return false;
        }
        if (Number(selectedMinute) > 59) {
            this.broadcastTime.minute = 59;
            return false;
        }
        if (selectedHour === time.hours() && selectedMinute < time.minutes()) {
            return false;
        }
        if (selectedHour === this.openedModalTime.hour && selectedMinute > this.openedModalTime.minute) {
            return false;
        }
        if ((selectedHour < time.hours()) || (selectedHour > this.openedModalTime.hour && selectedHour !== time.hours())) {
            return false;
        }
        return true;
    }

    checkSelectedType() {
        const time = moment(this.shiftData.details.arrival.date);
        const hour = time.hours();
        const minute = time.minutes();
        if ((Number(this.broadcastTime.hour) === hour && Number(this.broadcastTime.minute) === minute) || !this.isTimeValid) {
            this.selectedOption = null;
            return false;
        }
        return true;
    }

    getConditionValidated() {
        if (this.showConditions && this.conditionValidated && this.isTimeValid &&
            (!this.checkSelectedType() || (this.checkSelectedType() && this.selectedOption != null))) {
            return true;
        }
        if (!this.showConditions && this.isTimeValid &&
            (!this.checkSelectedType() || (this.checkSelectedType() && this.selectedOption != null))) {
            return true;
        }
        return false;
    }


}
