import { CounterSettings } from '@shared/models/settings/counterSettings/counterSettings';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CountersService } from './counters.service';
import * as moment from 'moment';
import { Counter } from 'src/app/shared/models/counters/counters';
import { UserService } from 'src/app/shared/services/user.service';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { BalanceDisplaying } from 'src/app/shared/enums/setting/counterSettings/balanceDisplaying.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent implements OnInit {

  private countersService = inject(CountersService);
  public userService = inject(UserService);
  private destroyRef = inject(DestroyRef);

  moment: any = moment;
  counters: Counter[] = [];
  errorGetResults: boolean;
  loadingResults = true;
  balanceDisplaying = BalanceDisplaying;
  format = Format;
  counterSettings: CounterSettings;

  ngOnInit() {
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSettings => {
      this.counterSettings = typoSettings.counterSettings;
    });
    this.getCounters();
  }

  getCounters() {
    this.countersService.getCounters()
      .pipe(
        tap((counters: Counter[]) => {
          this.counters = counters.map(counter => ({ ...counter }));
          this.loadingResults = false;
        }),
        catchError(() => {
          this.errorGetResults = true;
          this.loadingResults = false;
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
