import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class UserGuardService {

  isAuthenticated = false;
  cancelPendingRequests$ = new Subject();

  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(): boolean | Observable<boolean>  {
    if (this.isAuthenticated) {
      return true;
    }
    return this.userService.getUserDetails().pipe(
      map(
        userDetails => {
          if (!userDetails.account.mobimeAccess || userDetails.account.mobimeDisabled) {
            this.router.navigate(['/forbidden-access']);
            return false;
          }
          this.isAuthenticated = true;
          this.userService.setUserDetails(userDetails);
          this.userService.setUserSettings();
          const badgeToken = localStorage.getItem('badgeToken');
          if (this.userService.forceSetPassword && !badgeToken) {
            this.router.navigate(['/password-change']);
            return true;
          }
          this.navigateToLastRoute();
          return true;
        }
      )
    );
  }

 /**
  * check if mobime app is launched in the form of iFrame
  * @example mobime app could be imported by mobiNext app
  */
  isInIframe() {
    return window.self !== window.top;
  }

  navigateToLastRoute() {
    const lastRoute = sessionStorage.getItem('lastRoute');

    if (lastRoute && this.userService.token) {
      const [url, params] = lastRoute.split('?');
      this.router.navigate([url], { queryParams: this.getQueryParams(params) })
        .then(() => { })
        .catch(() => {
          this.router.navigate(['/home']);
        });
    }
  }

  getQueryParams(locationSearch: string) {
    if (!locationSearch) {
      return;
    }
    const params = locationSearch.split('&');
    return params.reduce((acc, curr) => {
      const param = curr.split('=');
      return { ...acc, [param[0]]: param[1] };
    }, {});
  }

  cancelPendingRequests() {
    this.cancelPendingRequests$.next(void 0);
  }
}

export const UserGuardResolver: CanActivateFn = (): boolean | Observable<boolean> => {
  return inject(UserGuardService).canActivate();
};
