import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { HomeService } from '../home.service';
import { HomeDayCalendar } from 'src/app/shared/models/home/homedayCalendar';
import { FrontActions } from 'src/app/shared/enums/actions.enum';
import { IPayloadShift } from 'src/app/shared/interfaces/payloadShift.interface';
import { UserService } from 'src/app/shared/services/user.service';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-planning-day',
  templateUrl: './planning-day.component.html',
  styleUrls: ['./planning-day.component.scss']
})
export class PlanningDayComponent implements OnInit {

  private homeService: HomeService = inject(HomeService);
  private userService: UserService = inject(UserService);
  private destroyRef = inject(DestroyRef);

  calenderData: HomeDayCalendar[];
  refreshSwiper = true;
  selectedDayIndex = 1;
  selectedShift = 0;
  scheduleSettings: ScheduleSettings;


  ngOnInit() {
    this.getCalenderData();
    this.userService.typologySetting
    .pipe(
      tap(settings => {
        this.scheduleSettings = settings.scheduleSettings;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  getCalenderData() {
    this.homeService.getCalenderData()
      .pipe(
        tap(calenderData => {
          this.calenderData = calenderData;
          this.initActiveCardIndex(this.calenderData[this.selectedDayIndex]);
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  selectShift(shiftIndex: number) {
    this.selectedShift = shiftIndex;
  }

  selectDay(itemIndex: number): void {
    if (this.selectedDayIndex !== itemIndex) {
      this.selectedDayIndex = itemIndex;
      this.refreshSwiper = false;
      setTimeout(() => this.refreshSwiper = true, 100);
      this.selectedShift = 0;
    }
  }

  handleActions(event: { action: FrontActions; payload: IPayloadShift }) {
    switch (event.action) {
      case FrontActions.CHECK_SHIFT:
        this.selectDay(event.payload.indexItem);
        this.selectShift(event.payload.indexShift);
        break;
      case FrontActions.PRISE_EN_SERVICE:
        break;
      default:
        break;
    }
  }

  /**
   * Active shift card must be initialized depending on current Date/Time (Now).
   *
   * If (Now < first daily shift) => 1st card is active.
   *
   * If (Now > last daily shift) => last card is active
   *
   * If (Now is between any card departure/arrival) => this card is active
   *
   * If (Now is between a card arrival and next card departure) => the later card is active
   *
   * @param dailyCalendar Daily calendar shifts
   */
  private initActiveCardIndex(dailyCalendar: HomeDayCalendar) {
    const now = new Date();
    if (!dailyCalendar || !dailyCalendar.parts.length || !dailyCalendar.parts[0].details) {
      return;
    }
    const lastShiftIndex = dailyCalendar.parts.length - 1;
    const dailyDeparture = new Date(dailyCalendar.parts[0].details.departure.date);
    const dailyArrival = new Date(dailyCalendar.parts[lastShiftIndex].details.arrival.date);

    if (now < dailyDeparture) {
      this.selectedShift = 0;
      return;
    }
    if (now > dailyArrival) {
      this.selectedShift = lastShiftIndex;
      return;
    }

    this.selectedShift = dailyCalendar.parts.findIndex((part, index) => {
      const departure = new Date(part.details.departure.date);
      const arrival = new Date(part.details.arrival.date);
      const previousArrival = index ? new Date(dailyCalendar.parts[index - 1].details.arrival.date) : null;
      return (now > departure && now < arrival) || (previousArrival && now > previousArrival && now < departure);
    });
  }
}
