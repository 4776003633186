<div class="profile ubuntu-regular">
  <div class="mobime-header">
    <h1 class="ubuntu-bold font-size-32 mb-5">
      {{ "ACCOUNT.TITLE" | translate }}
    </h1>
    <p class="font-size-13 text-grey">
      {{ "ACCOUNT.DESCRIPTION" | translate }}
      <br />
      {{ "ACCOUNT.DESCRIPTION_AFTER" | translate }}
    </p>
  </div>

  <div class="mobime-page-container">
    <div class="profile__user-infos">
      <h3 class="ubuntu-bold font-size-24 mb-3">
        {{ "ACCOUNT.ID_TITLE" | translate }}
      </h3>
      <div class="font-size-16 mb-5">
        {{ userService.login ? userService.login : "--" }}
      </div>

      <h3 class="ubuntu-bold font-size-24 mb-3">
        {{ "ACCOUNT.SITE_TITLE" | translate }}
      </h3>
      <div class="font-size-16 mb-5">
        {{ userService.userSite ? userService.userSite : "--" }}
      </div>
    </div>

    <form [formGroup]="changePasswordForm" *ngIf="displayChangePasswordForm" (ngSubmit)="onSubmit()"
      class="profile__change-password">
      <h3 class="ubuntu-bold font-size-24 mb-2">
        {{ "ACCOUNT.CHANGE_PASSWORD.TITLE" | translate }}
      </h3>
      <p class="font-size-13 text-grey mb-5">
        {{ "ACCOUNT.CHANGE_PASSWORD.TEXT" | translate }}
      </p>

      <div class="form-group mb-3">
        <label for="new-password" class="font-size-16 text-charcoal-grey">{{
          "ACCOUNT.CHANGE_PASSWORD.NEW_PASSWORD" | translate
          }}</label>
        <input class="font-size-16 w-100 bg-gallery br-4 px-3" type="password" id="new-password" name="new-password"
          formControlName="newPswd" [ngClass]="{
            error: (newPswd.errors || errorNewPswd) && formSubmitted
          }" />
        <p *ngIf="newPswd.errors && formSubmitted" class="font-size-13 mt-2 text-pomegranate">
          {{ "ACCOUNT.CHANGE_PASSWORD.ERROR_PASSWORD_RULES" | translate }}
        </p>
        <p class="font-size-13" [ngClass]="
            newPswd.errors && formSubmitted
              ? 'text-pomegranate'
              : 'text-grey mt-2'
          ">
          {{ "ACCOUNT.CHANGE_PASSWORD.PASSWORD_RULES" | translate }}
        </p>
      </div>

      <div class="form-group mb-5">
        <label for="new-password-confirm" class="font-size-16 text-charcoal-grey">{{
          "ACCOUNT.CHANGE_PASSWORD.CONFIRM_PASSWORD" | translate }}</label>
        <input class="font-size-16 w-100 bg-gallery br-4 px-3" type="password" id="new-password-confirm"
          name="new-password-confirm" formControlName="newPswdConfirm"
          [ngClass]="{ error: newPswdConfirm.errors && formSubmitted }" />
        <p *ngIf="newPswdConfirm.errors?.mustMatch && formSubmitted" class="font-size-13 mt-2 text-pomegranate">
          {{ "ACCOUNT.CHANGE_PASSWORD.ERROR_PASSWORD_CONFIRM" | translate }}
        </p>
      </div>

      <div class="d-flex justify-content-start">
        <button
          class="btn btn-black ubuntu-bold font-size-16 pointer d-flex align-items-center justify-content-center br-4">
          {{ "ACCOUNT.CHANGE_PASSWORD.BUTTON" | translate }}
        </button>
      </div>
    </form>

    <form class="notification-choice" [formGroup]="notificationChoiceForm" *ngIf="
        notificationChoiceSettings?.status === 'ACTIVE';
      " (ngSubmit)="saveNotifactions()">
      <h3 class="ubuntu-bold font-size-24 mt-5">
        {{ "ACCOUNT.NOTIFICATION.TITLE" | translate }}
      </h3>

      <div class="row form-group mb-3 mt-4" *ngIf="notificationChoiceSettings?.mail">
        <div class="col">
          <label for="new-password" class="font-size-16 text-charcoal-grey pt-4">
            {{ "ACCOUNT.NOTIFICATION.EMAIL" | translate }}</label>
        </div>
        <div class="col">
          <input class="font-size-16  bg-gallery br-4 px-3" type="email" id="email" name="mail"
            formControlName="mail" />
          <p class="ubuntu-regular font-size-12 errors"
            *ngIf="form.mail.errors?.pattern || form.mail.errors?.maxlength">
            {{ "ACCOUNT.NOTIFICATION.EMAIL_ERROR" | translate }}
          </p>
        </div>
      </div>

      <div class="row form-group mb-3 mt-4" *ngIf="notificationChoiceSettings?.mail">
        <div class="col">
          <label for="new-password" class="font-size-16 text-charcoal-grey pt-4">
            {{ "ACCOUNT.NOTIFICATION.SMS" | translate }}</label>
        </div>
        <div class="col">
          <input class="font-size-16  bg-gallery br-4 px-3" type="tel" value="+" id="sms" name="sms"
            formControlName="sms" (keypress)="validatePhoneNumberInput($event)"
            (blur)="formatPhoneNumber('sms', form.sms.value)" />
          <p class="ubuntu-regular font-size-12 errors" *ngIf="form.sms.errors?.invalidPhoneNumber">
            {{ "ACCOUNT.NOTIFICATION.PHONE_NUMBER_ERROR" | translate }}
          </p>
        </div>
      </div>

      <div class="row form-group mb-3 mt-4" *ngIf="notificationChoiceSettings?.mail">
        <div class="col">
          <label for="new-password" class="font-size-16 text-charcoal-grey pt-4">
            {{ "ACCOUNT.NOTIFICATION.VOICE" | translate }}</label>
        </div>
        <div class="col">
          <input class="font-size-16  bg-gallery br-4 px-3" type="tel" value="+" id="voice" name="new-password"
            formControlName="voice" (keypress)="validatePhoneNumberInput($event)"
            (blur)="formatPhoneNumber('voice', form.voice.value)" />
          <p class="ubuntu-regular font-size-12 errors" *ngIf="form.voice.errors?.invalidPhoneNumber">
            {{ "ACCOUNT.NOTIFICATION.PHONE_NUMBER_ERROR" | translate }}
          </p>
        </div>
      </div>

      <p class="font-size-16 text-grey mt-3 mb-5">
        {{ "ACCOUNT.NOTIFICATION.MESSAGE" | translate }}
      </p>

      <div class="d-flex justify-content- mt-4">
        <button
          class="btn btn-black ubuntu-bold font-size-16 pointer d-flex align-items-center justify-content-center br-4 px-5">
          {{ "ACCOUNT.NOTIFICATION.BUTTON" | translate }}
        </button>
      </div>

    </form>
  </div>
</div>

