import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FrontActions } from 'src/app/shared/enums/actions.enum';
import { IPayloadShift } from 'src/app/shared/interfaces/payloadShift.interface';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import {HomeDayCalendar} from '@shared/models/home/homedayCalendar';

@Component({
  selector: 'app-planning-days-carousel',
  templateUrl: './planning-days-carousel.component.html',
  styleUrls: ['./planning-days-carousel.component.scss'],
})


export class PlanningDaysCarouselComponent {
  @ViewChild('swiperCardsContainer') swiper!: ElementRef<SwiperContainer>;
  @Input() calendarData: HomeDayCalendar[] = [];
  @Input() checkedShift = 0;
  @Input() checkedItem = 1;

  @Output() actions = new EventEmitter<{ action: FrontActions; payload: IPayloadShift }>();
  @Output() selectItem = new EventEmitter<number>();

  swiperConfig: SwiperOptions = {
    navigation: true,
    width: 293,
    spaceBetween: 16,
    slidesPerView: 'auto',
  };

  handleActions(event: { action: FrontActions; payload: IPayloadShift }): void {
    this.actions.emit(event);
  }

  cardClick(shiftIndex: number) {
    this.selectItem.emit(shiftIndex);
  }
}
