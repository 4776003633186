import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, finalize, Observable, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { AuthenticationService } from 'src/app/auth.service';
import { UserGuardService } from '../services/user-guard.service';
import { UserParams } from '@shared/models/user-details.model';
import { LoaderModalService } from "@shared/services/loader-modal.service";

const BADGE_TOKEN_LOCALSTORAGE = 'badgeToken';
const DEFAULT_DEVICE_LOCALSTORAGE = 'default_device';
const ADMIN_LOCALSTORAGE = 'admin';
const LASTROUTE_LOCALSTORAGE = 'lastRoute';
const USERPARAMS_LOCALSTORAGE = 'userParams';

const AUTHORIZATION_HEADER = 'Authorization';
const AUTHORIZATION_BADGE_HEADER = 'Authorization-Badge';
const DEVICEID_HEADER = 'deviceId';
const DEVICETYPE_HEADER = 'deviceType';
const DEVICECODE_HEADER = 'deviceCode';
const PILOT_HEADER = 'pilot';
const DESKTOP_HEADER_VALUE = 'DESKTOP';



@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  private userService = inject(UserService);
  private authService = inject(AuthenticationService);
  private userGuard = inject(UserGuardService);
  private loaderService = inject(LoaderModalService);
  private totalRequests = 0;
  private showLoader = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    this.totalRequests++;
    let defaultDevice = null;
    try {
      defaultDevice = JSON.parse(localStorage.getItem(DEFAULT_DEVICE_LOCALSTORAGE));
    } catch (error) {
      console.log(error);
    }

    let newHeaders = req.headers;

    const storedUserParamJSON = localStorage.getItem(USERPARAMS_LOCALSTORAGE);
    if (storedUserParamJSON) {
      const storedUserParams: UserParams = JSON.parse(storedUserParamJSON);
      newHeaders = newHeaders.append(PILOT_HEADER, storedUserParams.pilot.toString());
    }

    const badgeToken = localStorage.getItem(BADGE_TOKEN_LOCALSTORAGE);

    if (this.userService.token) {
      newHeaders = newHeaders.append(AUTHORIZATION_HEADER, this.userService.token);
    } else if (badgeToken) {
      newHeaders = newHeaders.append(AUTHORIZATION_BADGE_HEADER, badgeToken);
    }
    if (!req.url.includes('open/')) {
      if (localStorage.getItem(ADMIN_LOCALSTORAGE)) {
        newHeaders = newHeaders.append(ADMIN_LOCALSTORAGE, localStorage.getItem(ADMIN_LOCALSTORAGE));
      }
    }

    const shouldIgnoreLoader = req.headers.has('Ignore-Loader');

    if (this.totalRequests && !this.showLoader && !shouldIgnoreLoader) {
      this.loaderService.show();
      this.showLoader = true;
    }

    newHeaders = newHeaders.append(DEVICEID_HEADER, defaultDevice && defaultDevice.id ? `${defaultDevice.id}` : '0');
    newHeaders = newHeaders.append(DEVICETYPE_HEADER, defaultDevice && defaultDevice.type ? defaultDevice.type : DESKTOP_HEADER_VALUE);
    // name is deviceCode
    newHeaders = newHeaders.append(DEVICECODE_HEADER, defaultDevice && defaultDevice.name ? defaultDevice.name : DEVICECODE_HEADER);
    const authReq = req.clone({ headers: newHeaders });
    return next.handle(authReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // badge mode
          if (err.status === 403 && badgeToken) {
            return of(void 0).pipe(tap(() => this.authService.logout()));
          }
          // auth mode
          if (err.status === 403 && !badgeToken && !err.url.includes('open/')) {
            sessionStorage.setItem(LASTROUTE_LOCALSTORAGE, location.pathname + location.search);
            return of(void 0).pipe(tap(() => this.authService.signIn()));
          }
          if (err.status === 401 && !badgeToken) {
            return of(void 0).pipe(tap(() => this.authService.forbidden()));
          }
        }

        return EMPTY;
      }),
      finalize(() => {
        if (!shouldIgnoreLoader) {
          this.showLoader = false;
          this.loaderService.dismiss();
        }
      }),
      takeUntil(this.userGuard.cancelPendingRequests$)
    );
  }
}
