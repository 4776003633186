import { PartialAbsenceRequestService } from './partial-absence-request.service';
import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-partial-absence-request',
  templateUrl: './partial-absence-request.component.html'
})
export class PartialAbsenceRequestComponent implements OnDestroy {

  constructor(public partialAbsenceRequestService: PartialAbsenceRequestService) { }

  ngOnDestroy() {
    this.partialAbsenceRequestService.step = 1;
    this.partialAbsenceRequestService.absenceData =  {
      groupingId: null,
      startDate: null,
      counterCode: null,
      counterLabel: null,
      startTime: { hour: null, minute: null },
      endTime: { hour: null, minute: null },
      comment: null
    };
  }
}
