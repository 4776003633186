import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FrontActions } from 'src/app/shared/enums/actions.enum';
import { IPayloadShift } from 'src/app/shared/interfaces/payloadShift.interface';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Component({
  selector: 'app-planning-status',
  templateUrl: './planning-status.component.html',
  styleUrls: ['./planning-status.component.scss']
})
export class PlanningStatusComponent implements OnChanges {

  @Input() numberOfPoints: number;
  @Input() code: string;
  @Input() indexItem: number;
  @Input() checkedItem: number;
  @Input() checkedShift: number;
  @Input() workingTime: boolean;
  @Input() serviceName: string;
  @Input() displayVacationCount: boolean;
  @Input() displayServiceName: boolean;
  @Input() period: PeriodType;
  @Input() type: DayType;
  @Output() actions: EventEmitter<{action: FrontActions, payload: any}> = new EventEmitter();
  FrontActions = FrontActions;
  shifts: number[] = [0];
  periodType = PeriodType;
  dayType = DayType;


  ngOnChanges() {
    if (this.numberOfPoints !== -1) {
      this.shifts = Array(this.numberOfPoints).fill(0).map((i) => i);
    }
  }

  handleActions(action: FrontActions, payload: IPayloadShift) {
    this.actions.emit({action, payload});
  }

}
