// eslint-disable-next-line max-len
import { FullDayAbsenceCounterRequestComponent } from './fullDay-absence-request/fullDay-absence-counter-request/fullDay-absence-counter-request.component';
import { PartialAbsenceCalendarComponent } from './partial-absence-request/partial-absence-calendar/partial-absence-calendar.component';
import { PartialAbsenceRequestService } from './partial-absence-request/partial-absence-request.service';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AbsenceComponent } from './absence.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbsenceHomeComponent } from './home/absence-home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AbsenceHistoryComponent } from './history/absence-history.component';
import { PartialAbsenceRequestComponent } from './partial-absence-request/partial-absence-request.component';
// eslint-disable-next-line max-len
import {
  PartialAbsenceRequestCountersComponent
} from './partial-absence-request/partial-absence-request-counters/partial-absence-request-counters.component';
import {PartialAbsenceRecapComponent} from './partial-absence-request/partial-absence-recap/partial-absence-recap.component';
import {PartialAbsenceSelectComponent} from './partial-absence-request/partial-absence-select/partial-absence-select.component';
import {CalendarModule} from 'angular-calendar';
import {AbsenceService} from './absence.service';
import {FullDayAbsenceRequestService} from './fullDay-absence-request/fullDay-absence-request.service';
import {FullDayAbsenceRequestComponent} from './fullDay-absence-request/fullDay-absence-request.component';
import {FullDayAbsenceSelectComponent} from './fullDay-absence-request/fullDay-absence-select/fullDay-absence-select.component';
import {FullDayAbsenceCalendarComponent} from './fullDay-absence-request/fullDay-absence-calendar/fullDay-absence-calendar.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CounterBalanceModalComponent} from './counter-balance-modal/counter-balance-modal.component';
import {FullDayAbsenceRecapComponent} from './fullDay-absence-request/fullDay-absence-recap/fullDay-absence-recap.component';
import {AbsenceDetailsComponent} from './details/details.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    SharedModule,
    RouterModule,
    CalendarModule,
    ReactiveFormsModule

  ],
  declarations: [
    AbsenceComponent,
    AbsenceHomeComponent,
    AbsenceHistoryComponent,
    PartialAbsenceRequestComponent,
    PartialAbsenceSelectComponent,
    PartialAbsenceRequestCountersComponent,
    PartialAbsenceRecapComponent,
    PartialAbsenceCalendarComponent,
    FullDayAbsenceRequestComponent,
    FullDayAbsenceSelectComponent,
    FullDayAbsenceCalendarComponent,
    FullDayAbsenceCounterRequestComponent,
    FullDayAbsenceRecapComponent,
    AbsenceDetailsComponent,
    CounterBalanceModalComponent,

  ],
  providers: [PartialAbsenceRequestService, AbsenceService, FullDayAbsenceRequestService, NgbActiveModal],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AbsenceModule {
}
