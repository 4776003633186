import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize, tap } from 'rxjs';
import { NewsDetailsModel } from 'src/app/shared/models/news/newsDetails.model';
import { NewsListItemModel } from 'src/app/shared/models/news/newsListItem.model';
import { NewsApiService } from 'src/app/shared/services/api/news.api.service';

@Injectable()
export class NewsService {
  /**
   * All fetched items
   */
  list: Array<NewsListItemModel> = [];
  /**
   * Indicates whether the service is about
   * to fetch items from the server
   */
  isLoadingList = false;
  /**
   * Next page index of the list
   * Used to set the page search query for the web service
   */
  nextPageIndex = 0;
  /**
   * Indicates whether the service is about
   * to fetch items from the server
   */
  isLoadingDetails = false;
  /**
   * Current active model
   */
  currentDetailsItem: NewsDetailsModel;
  private newsApiService = inject(NewsApiService);
  private destroyRef = inject(DestroyRef);

  /**
   * Rest list function, used to reset page index and items list
   * FUTURE_USAGE
   */
  resetList() {
    this.list = [];
    this.nextPageIndex = 0;
  }

  /**
   * Function to fetch more items
   */
  fetchMore() {
    this._fetchList();
  }

  /**
   * Fetch more items in the list
   * This function is used internally only
   */
  private _fetchList() {
    // empty it for now
    this.resetList();
    this.isLoadingList = true;
    this.newsApiService.list()
      .pipe(
        tap(items => {
          this.nextPageIndex++;
          if (items && items.length) {
            items.map(e => {
              this.list.push(new NewsListItemModel(e));
            });
          }
        }),
        finalize(() => this.isLoadingList = false),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  /**
   * Load item details.
   * Get an article details
   * We return a promise because the component should be
   * notified when the details is ready
   */
  fetchItem(id: number): Promise<any> {
    return new Promise((resolve) => {
      this.isLoadingDetails = true;
      this.newsApiService.read(id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (details) => {
            this.currentDetailsItem = new NewsDetailsModel(details);
            this.isLoadingDetails = false;
            resolve(true);
          },
          error: () => {
            this.isLoadingDetails = false;
            resolve(true);
          }
        });
    });
  }
}
