<div class="message-container ubuntu-regular" (click)="displayActions($event)">
  <div class="mobime-header header">
    <h1 class="title ubuntu-bold font-size-32 mb-0"> {{'MESSAGE.TITLE' | translate}}</h1>
    <div class="search d-flex">
      <div class="input-group d-flex">
        <div class="search-bar position-relative">
          <input class="font-size-16 w-100 h-100" type="text" #messageSearchInput [placeholder]="'COMMON.SEARCH' | translate"
            id="searchMessageInput" [(ngModel)]="keyWords" (keyup.enter)="search(keyWords)">
          <div class="icon icon-16 icon-search position-absolute"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="content py-5" *ngIf="messageGroupResponse" (scroll)="onScroll($event)">
    <!-- Groups which contain a list of message -->
    <ng-container *ngFor="let messageGroup of messageGroupResponse.messageGroups">
      <div *ngIf="messageGroup.messages?.length" class="messageGroup">
        <div class="period ubuntu-bold font-size-24 mb-3">
          {{messageGroup.label | translate}}
        </div>
        <div class="messages-container">
          <!-- List of messages -->
          <div *ngFor="let message of messageGroup.messages" class="message d-flex position-relative">
            <div class="favorite h-100 position-relative">
              <div class="icon icon-16 position-absolute pointer" (click)="addToFavorites(message)"
                [ngClass]="message.favorite ? 'icon-star-active' : 'icon-star'"></div>
            </div>
            <div class="message-type h-100 position-relative" (click)="openMessage(message)"
              [ngClass]="{'pointer': message.type === messageType.CUSTOM_MESSAGE ||
              (message.type === messageType.UPDATED_SHIFT && message.concernDate) ||
              (message.type === messageType.VALIDATION_REFUSAL_ABSENCE) ||
              (((message.type === messageType.ACCEPTANCE_EXCHANGE_SHIFT) ||
              (message.type === messageType.ACCEPTANCE_REFUSAL_REST) ||
              (message.type === messageType.PROPOSAL_EXCHANGE_SHIFT) ||
              (message.type === messageType.PROPOSAL_EXCHANGE_REST) ||
              (message.type === messageType.VALIDATION_REFUSAL_REST)||
              (message.type === messageType.VALIDATION_REFUSAL_SHIFT)) && swapRequestIsActive) ||
              (message.type === messageType.RELAUNCH_SURVEY)  }">
              <!-- needs to modify -->
              <ng-container [ngSwitch]="message.type">
                <ng-container *ngSwitchCase="messageType.CUSTOM_MESSAGE">
                  <div class="position-absolute type-background bg-pomegranate">
                    <div class="icon icon-symbole-transdev"></div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="messageType.UPDATED_SHIFT">
                  <div class="position-absolute type-background bg-bostonBlue">
                    <div class="icon icon-message-planing"></div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="messageType.VALIDATION_REFUSAL_ABSENCE">
                  <div class="position-absolute type-background bg-bostonBlue">
                    <div class="icon icon-message-door"></div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="messageType.RELAUNCH_SURVEY">
                  <div class="position-absolute type-background survey-color">
                    <div class="icon icon-message-sondages"></div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="position-absolute type-background bg-bostonBlue">
                    <div class="icon icon-message-exchanges-demande"></div>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <div class="message-body overflow-hidden ms-4 mt-4" (click)="openMessage(message)"
              [ngClass]="{
                'pointer': message.type === messageType.CUSTOM_MESSAGE ||
              (message.type === messageType.UPDATED_SHIFT && message.concernDate) ||
              (message.type === messageType.VALIDATION_REFUSAL_ABSENCE) ||
              (((message.type === messageType.ACCEPTANCE_EXCHANGE_SHIFT) ||
              (message.type === messageType.ACCEPTANCE_REFUSAL_REST) ||
              (message.type === messageType.PROPOSAL_EXCHANGE_SHIFT) ||
              (message.type === messageType.PROPOSAL_EXCHANGE_REST) ||
              (message.type === messageType.VALIDATION_REFUSAL_REST) ||
              (message.type === messageType.VALIDATION_REFUSAL_SHIFT)) && swapRequestIsActive) ||
              (message.type === messageType.RELAUNCH_SURVEY),
              'text-grey': message.markedRead,
              'text-black': !message.markedRead
              }">
              <div class="message-subjet align-items-center">
                <div class="message-title font-size-16 text-truncate me-2"
                  [ngClass]="{'ubuntu-bold': !message.markedRead}">
                  {{ (message.title ? message.title : 'MESSAGE.TYPE.'+message.type) | translate}}
                </div>
                <div *ngIf="message.status" class="message-status d-inline-flex align-items-center p-1">
                  <div class="icon icon-8 icon-clock-white me-2"></div>
                  <div class="status font-size-12 text-truncate text-white">{{message.status}}</div>
                </div>
              </div>
              <div class="message-text w-100 font-size-13 overflow-hidden">
                <div [ngStyle]="{'-webkit-line-clamp': !message.status? 2 : 1}" [innerHTML]="message.content | linky | nl2br"></div>
              </div>
            </div>

            <div *ngIf="!message.showActions"
              class="setting position-absolute d-flex flex-column align-items-end h-100">
              <div class="time font-size-10 text-grey mb-3">
                {{message.creationDate | dateFormat: 'short'}} {{message.creationDate | dateFormat: 'HH:mm'}}
              </div>
              <div class="icon icon-16 icon-ellipsis-vertical pointer" *ngIf = "message.type !== messageType.CUSTOM_MESSAGE || message.type === messageType.CUSTOM_MESSAGE && message.markedRead" (click)="displayActions($event,message)"></div>

            </div>

            <div *ngIf="message.showActions" class="actions position-absolute h-100 d-flex">
              <div (click)="markAsRead(message)" class="action-read position-relative h-100 pointer"
                *ngIf="message.type !== messageType.CUSTOM_MESSAGE || (message.type === messageType.CUSTOM_MESSAGE && message.markedRead)">
                <div class="action position-absolute d-flex flex-column align-items-center">
                  <div class="icon icon-16 icon-message-not-read"></div>
                  <div class="font-size-8 text-white text-nowrap">
                    {{ (message.markedRead?  'MESSAGE.NOT_READ' : 'MESSAGE.READ') | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Fin list of message -->
        </div>
      </div>
    </ng-container>
    <!-- Fin group -->
  </div>
</div>
