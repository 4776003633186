const country = (localStorage.getItem('country') ? localStorage.getItem('country') : '').toLowerCase();
export const HOST = `https://api.testing.${country}.mobime.transdev.com`;
export const MOBI_ADMIN_HOST = `https://api.testing.${localStorage.getItem('country')}.mobiservices.transdev.net/mobi-admin`;
const AUTH_HOST = getAuthHost();
export const APP_ID = getAppID();country
export const WEBSOCKET_HOST = getWebSocket();

export const HCAPTCHA_SITE_KEY = getHCatpchaSiteKey();
export const HCAPTCHA_SECRET_KEY = getHCatpchaSecretKey();

const CALLBACK_URL = `https://testing.${country}.mobime.transdev.com`;

export const AUTH_URL = {
  AUTHORIZE: `${AUTH_HOST}/oauth2/authorize?client_id=${APP_ID}&response_type=token` +
             `&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${CALLBACK_URL}`,
  LOGOUT: `${AUTH_HOST}/logout?client_id=${APP_ID}&response_type=token` +
             `&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${CALLBACK_URL}`,
};

export const mobiAdminDomaine = `https://testing.${country}.mobiservices.transdev.net`;

export const environment = {
  appVersion: require('../../package.json').version + '-testing',
  production: true
};

export const CALENDAR_PROPS = {
  NBR_DAYS: 30,
  PLANNING_DAYS: 365
};

export const GIT_URLS = {
  REPO_MOBINEXT_COMMIT: 'https://github.com/transdev-corp/MobiNext/commit/'
};


function getAppID() {
  switch (country) {
    case 'fr':
      return '2t12lrr70jbbporf01pjfi6rmn';
    case 'ca':
      return 'N/A';
    default:
      return 'N/A';
  }
}

function getAuthHost() {
  switch (country) {
    case 'fr':
      return 'https://testing-mobime-transdev.auth.eu-west-1.amazoncognito.com';
    case 'ca':
      return 'N/A';
    default:
      return 'N/A';
  }
}

function getWebSocket() {
  switch (country) {
    case 'fr':
      return 'wss://nk7svddmij.execute-api.eu-west-1.amazonaws.com/Prod';
    case 'ca':
      return 'wss://N/A';
    default:
      return 'wss://N/A';
  }
}

function getHCatpchaSiteKey() {
  switch (country) {
    case 'fr':
      return '8bbc6c30-5f9e-462a-b8f0-c2e43e76721e';
    case 'ca':
      return 'N/A';
    default:
      return 'N/A';
  }
}

function getHCatpchaSecretKey() {
  switch (country) {
    case 'fr':
      return 'ES_462435cd42b34c81a21cd03ed2a37a64';
    case 'ca':
      return 'N/A';
    default:
      return 'N/A';
  }
}

