import { Component, DestroyRef, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AccountPasswordService } from '@shared/services/account-password.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-password-rest',
  templateUrl: './password-rest.component.html',
  styleUrls: ['./password-rest.component.scss']
})
export class PasswordRestComponent {

  private formBuilder = inject(UntypedFormBuilder);
  private accountPasswordService = inject(AccountPasswordService);
  private snackbarService = inject(SnackbarService);
  public userService = inject(UserService);
  private router = inject(Router);
  private activeModal = inject(NgbActiveModal);
  private destroyRef = inject(DestroyRef);

  changePasswordForm =  this.formBuilder.group({
    newPswd: [null, [
      Validators.required,
      Validators.minLength(8),
      this.accountPasswordService.passwordValidator(),
    ]],
    newPswdConfirm: [null, [
      Validators.required,
      Validators.minLength(8),
    ]],
  }, { validator : this.accountPasswordService.mustMatch('newPswd', 'newPswdConfirm')});

  errorOldPswd: boolean;
  errorNewPswd: boolean;
  formSubmitted: boolean;
  isLoading = false;


  get newPswd() { return this.changePasswordForm.get('newPswd'); }
  get newPswdConfirm() { return this.changePasswordForm.get('newPswdConfirm'); }


  onSubmit() {
    this.formSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.modifyPassword();
  }

  resetErrors() {
    this.errorOldPswd = false;
    this.errorNewPswd = false;
  }

  modifyPassword() {
    this.accountPasswordService.modifyPassword(
      this.userService.accessToken,
      this.newPswd.value
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (modifyResult) => {
        if (modifyResult.success) {
          this.snackbarService.show('ACCOUNT.MODAL.PASSWORD_MODIFIED.TEXT', 'success');
          this.userService.forceSetPassword = false;
          this.router.navigate(['/home']);
          this.activeModal.close();
        } else {
          this.resetErrors();
          if (modifyResult.code === 1) {
            // *ERROR OLD PASSWORD
            this.errorOldPswd = true;
            this.snackbarService.show('ACCOUNT.CHANGE_PASSWORD.ERROR_OLD_PASSWORD', 'error');
          } else if (modifyResult.code === 2) {
            // *ERROR NEW PASSWORD
            this.errorNewPswd = true;
            this.snackbarService.show('ACCOUNT.CHANGE_PASSWORD.ERROR_NEW_PASSWORD', 'error');
          } else {
            // *ERROR OTHER
            this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
          }
        }

      },
      error: () => {
        this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
      }
    }
    );
  }
}
